import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import newsStyles from "../styles/pages/news.module.css"

const NewsPage = () => (
    <Layout location="news">
        <SEO title="News" />
        <div className={ newsStyles.noNews }>
            <div className={ newsStyles.message }>
                <div className={ newsStyles.messageWrapper }>
                    <h1>No articles found //</h1>
                    <h3>Stay tuned for updates in the future.</h3>
                    <button onClick={() => {
                        window.history.back()
                    }}>Go Back</button>
                </div>
            </div>
        </div>
    </Layout>
)

export default NewsPage